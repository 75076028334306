import { 
  advancedSeachModel,
  entitys, 
  matchesFilterFields, 
  commonOperators, 
  usersRolesFilterFields, 
  applicationsFilterFields, 
  trainingFilterFields,
  aggregate,
  aggregateTypes,
  aggregateTypesDisplay,
  numericalStringDisplay,
  operatorCommonDisplay
} from './advancedSearch';
import { 
  userRoles, 
  matchStatus,
  trainingStatus,
  formStatus,
  userStatus,
  filters
} from "@models";


export const ruleTemplates = [
  { 
    name: 'Matched Participants', 
    entity: {id: entitys.MATCHES, name: 'Matches'}, 
    filters: [
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [{ 
          field: matchesFilterFields.MATCH_STATUS,
          op: commonOperators.OP_EQUAL_TO,
          value: matchStatus.ACTIVE
        }]
      }] 
  },
  { 
    name: 'Matched Mentors', 
    entity: {id: entitys.MATCHES, name: 'Matches'},
    filters: [
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [
          { 
            field: matchesFilterFields.MATCH_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: matchStatus.ACTIVE
          },
          { 
            field: matchesFilterFields.MATCH_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          }
        ],
        type: entitys.MATCHES
      },
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          }
        ],
        type: entitys.USER_ROLES
      }
    ]
      
  },
  { 
    name: 'Matched Mentees', 
    entity: {id: entitys.MATCHES, name: 'Matches'},
    filters: [
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [
          { 
            field: matchesFilterFields.MATCH_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: matchStatus.ACTIVE
          },
          { 
            field: matchesFilterFields.MATCH_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          }
        ],
        type: entitys.MATCHES
      },
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          }
        ],
        type: entitys.USER_ROLES
      }
    ]
      
  },
  { 
    name: 'Mentors With Incomplete Application', 
    entity: {id: entitys.MATCHES, name: 'Matches'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_NOT_EQUAL_TO,
            value: formStatus.COMPLETED
          }
        ],
        type: entitys.APPLICATIONS
      },
    ]
      
  },
  { 
    name: 'Mentors With Complete Application', 
    entity: {id: entitys.MATCHES, name: 'Matches'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          }
        ],
        type: entitys.APPLICATIONS
      },
    ]
  },
  { 
    name: 'Mentees With Incomplete Application', 
    entity: {id: entitys.MATCHES, name: 'Matches'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_NOT_EQUAL_TO,
            value: formStatus.COMPLETED
          }
        ],
        type: entitys.APPLICATIONS
      },
    ]
      
  },
  { 
    name: 'Mentees With Complete Application', 
    entity: {id: entitys.MATCHES, name: 'Matches'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          }
        ],
        type: entitys.APPLICATIONS
      },
    ]
      
  },
  { 
    name: 'Mentees who haven`t completed the training module', 
    entity: {id: entitys.TRAININGS, name: 'Trainings'},
    filters: [
      {
        ...advancedSeachModel[entitys.TRAININGS],
        fieldDisplay: advancedSeachModel[entitys.TRAININGS].field,
        operatorDisplay: advancedSeachModel[entitys.TRAININGS].op,
        valueDisplay:  advancedSeachModel[entitys.TRAININGS].values,
        type: entitys.TRAININGS,
        expressions: [
          { 
            field: trainingFilterFields.TRAINING_STATUS,
            op: commonOperators.OP_NOT_EQUAL_TO,
            value: trainingStatus.COMPLETE
          },
          { 
            field: trainingFilterFields.TRAINING_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          }
        ],
        type: entitys.TRAININGS
      },
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      }
    ]
      
  },
  { 
    name: 'Mentees who have started and or completed the training module', 
    type: filters.FILTER_SET_ANY,
    entity: {id: entitys.TRAININGS, name: 'Trainings'},
    filters: [
      {
        ...advancedSeachModel[entitys.TRAININGS],
        fieldDisplay: advancedSeachModel[entitys.TRAININGS].field,
        operatorDisplay: advancedSeachModel[entitys.TRAININGS].op,
        valueDisplay:  advancedSeachModel[entitys.TRAININGS].values,
        type: entitys.TRAININGS,
        expressions: [
          { 
            field: trainingFilterFields.TRAINING_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: trainingStatus.STARTED
          },
          { 
            field: trainingFilterFields.TRAINING_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          }
        ],
        type: entitys.TRAININGS
      },
      {
        ...advancedSeachModel[entitys.TRAININGS],
        fieldDisplay: advancedSeachModel[entitys.TRAININGS].field,
        operatorDisplay: advancedSeachModel[entitys.TRAININGS].op,
        valueDisplay:  advancedSeachModel[entitys.TRAININGS].values,
        type: entitys.TRAININGS,
        expressions: [
          { 
            field: trainingFilterFields.TRAINING_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: trainingStatus.COMPLETE
          },
          { 
            field: trainingFilterFields.TRAINING_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          }
        ],
        type: entitys.TRAININGS
      }
    ]
  },
  { 
    name: 'Mentors who haven`t completed the training module', 
    entity: {id: entitys.TRAININGS, name: 'Trainings'},
    filters: [
      {
        ...advancedSeachModel[entitys.TRAININGS],
        fieldDisplay: advancedSeachModel[entitys.TRAININGS].field,
        operatorDisplay: advancedSeachModel[entitys.TRAININGS].op,
        valueDisplay:  advancedSeachModel[entitys.TRAININGS].values,
        type: entitys.TRAININGS,
        expressions: [
          { 
            field: trainingFilterFields.TRAINING_STATUS,
            op: commonOperators.OP_NOT_EQUAL_TO,
            value: trainingStatus.COMPLETE
          },
          { 
            field: trainingFilterFields.TRAINING_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          }
        ],
        type: entitys.TRAININGS
      }
    ]
  },
  {
    
    name: 'Mentors those who have started and or completed the training module', 
    type: filters.FILTER_SET_ANY,
    entity: {id: entitys.TRAININGS, name: 'Trainings'},
    filters: [
      {
        ...advancedSeachModel[entitys.TRAININGS],
        fieldDisplay: advancedSeachModel[entitys.TRAININGS].field,
        operatorDisplay: advancedSeachModel[entitys.TRAININGS].op,
        valueDisplay:  advancedSeachModel[entitys.TRAININGS].values,
        type: entitys.TRAININGS,
        expressions: [
          { 
            field: trainingFilterFields.TRAINING_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: trainingStatus.STARTED
          },
          { 
            field: trainingFilterFields.TRAINING_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          }
        ],
        type: entitys.TRAININGS
      },
      {
        ...advancedSeachModel[entitys.TRAININGS],
        fieldDisplay: advancedSeachModel[entitys.TRAININGS].field,
        operatorDisplay: advancedSeachModel[entitys.TRAININGS].op,
        valueDisplay:  advancedSeachModel[entitys.TRAININGS].values,
        type: entitys.TRAININGS,
        expressions: [
          { 
            field: trainingFilterFields.TRAINING_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: trainingStatus.COMPLETE
          },
          { 
            field: trainingFilterFields.TRAINING_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          }
        ],
        type: entitys.TRAININGS
      }
    ]
  },
  { 
    name: 'Unmatched mentors with completed applications', 
    entity: {id: entitys.MATCHES, name: 'Matches'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          }
        ],
        type: entitys.APPLICATIONS
      },
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [
          { 
            field: matchesFilterFields.MATCH_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: matchStatus.ACTIVE
          },
          { 
            field: matchesFilterFields.MATCH_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          }
        ],
        type: entitys.MATCHES,
        aggregate: {
          ...aggregate,
          type: aggregateTypesDisplay[1].id,
          op: operatorCommonDisplay[0].id,
          value: 0
        }
      }
    ]
  },
  { 
    name: 'Participants with both roles', 
    entity: {id: entitys.USERS, name: 'Users'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR_AND_MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
    ]
  },
  { 
    name: 'Participants with both roles and applications complete', 
    entity: {id: entitys.USERS, name: 'Users'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR_AND_MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          }
        ],
        type: entitys.APPLICATIONS
      },
    ]
  },
  { 
    name: 'Participants locked with both roles', 
    entity: {id: entitys.USERS, name: 'Users'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR_AND_MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [
          {
            field: matchesFilterFields.MATCH_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: matchStatus.LOCKED
          },
        ],
        type: entitys.MATCHES,
        aggregate: {
          ...aggregate,
          typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
          type: aggregateTypesDisplay[1].id,
          op: numericalStringDisplay[0].id,
          value: 0
        }
      }
    ]
  },
  { 
    name: 'Mentors locked in more than one match', 
    entity: {id: entitys.USERS, name: 'Users'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [
          {
            field: matchesFilterFields.MATCH_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          {
            field: matchesFilterFields.MATCH_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: matchStatus.LOCKED
          },
        ],
        type: entitys.MATCHES,
        aggregate: {
          ...aggregate,
          typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
          type: aggregateTypesDisplay[1].id,
          op: numericalStringDisplay[0].id,
          value: 1
        }
      }
    ]
  },
  { 
    name: 'Mentors with Completed Matches', 
    entity: {id: entitys.USERS, name: 'Users'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [
          {
            field: matchesFilterFields.MATCH_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          {
            field: matchesFilterFields.MATCH_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: matchStatus.COMPLETED
          },
        ],
        type: entitys.MATCHES,
       
      }
    ]
  },
  { 
    name: 'Mentees with Completed Matches', 
    entity: {id: entitys.USERS, name: 'Users'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [
          {
            field: matchesFilterFields.MATCH_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          },
          {
            field: matchesFilterFields.MATCH_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: matchStatus.COMPLETED
          },
        ],
        type: entitys.MATCHES,
      }
    ]
  },
  { 
    name: 'Mentees Never Matched', 
    entity: {id: entitys.USERS, name: 'Users'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          }
        ],
        type: entitys.APPLICATIONS
      },
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [
          {
            field: matchesFilterFields.MATCH_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTEE
          },
        ],
        type: entitys.MATCHES,
        aggregate: {
          ...aggregate,
          typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
          type: aggregateTypesDisplay[1].id,
          op: commonOperators.OP_EQUAL_TO,
          value: 0
        }
      }
    ]
  },
  { 
    name: 'Mentors Never Matched', 
    entity: {id: entitys.USERS, name: 'Users'},
    filters: [
      {
        ...advancedSeachModel[entitys.USER_ROLES],
        fieldDisplay: advancedSeachModel[entitys.USER_ROLES].field,
        operatorDisplay: advancedSeachModel[entitys.USER_ROLES].op,
        valueDisplay:  advancedSeachModel[entitys.USER_ROLES].values,
        type: entitys.USER_ROLES,
        expressions: [
          { 
            field: usersRolesFilterFields.ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
          { 
            field: usersRolesFilterFields.STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: userStatus.ACTIVE
          }
        ],
        type: entitys.USER_ROLES
      },
      {
        ...advancedSeachModel[entitys.APPLICATIONS],
        fieldDisplay: advancedSeachModel[entitys.APPLICATIONS].field,
        operatorDisplay: advancedSeachModel[entitys.APPLICATIONS].op,
        valueDisplay:  advancedSeachModel[entitys.APPLICATIONS].values,
        type: entitys.APPLICATIONS,
        expressions: [
          { 
            field: applicationsFilterFields.APPLICATION_STATUS,
            op: commonOperators.OP_EQUAL_TO,
            value: formStatus.COMPLETED
          }
        ],
        type: entitys.APPLICATIONS
      },
      {
        ...advancedSeachModel[entitys.MATCHES],
        fieldDisplay: advancedSeachModel[entitys.MATCHES].field,
        operatorDisplay: advancedSeachModel[entitys.MATCHES].op,
        valueDisplay:  advancedSeachModel[entitys.MATCHES].values,
        type: entitys.MATCHES,
        expressions: [
          {
            field: matchesFilterFields.MATCH_ROLE,
            op: commonOperators.OP_EQUAL_TO,
            value: userRoles.MENTOR
          },
        ],
        type: entitys.MATCHES,
        aggregate: {
          ...aggregate,
          typeDisplay: [{ id: aggregateTypes.COUNT, name: 'Count'}],
          type: aggregateTypesDisplay[1].id,
          op: commonOperators.OP_EQUAL_TO,
          value: 0
        }
      }
    ]
  },
];
