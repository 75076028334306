import client from "./http";

class ApplicationService {
  constructor(axios) {
    this.axios = axios;
  }

  getApplicationSets(programId, applicationSetId) {
    return this.axios.get(`/programs/${programId}/application-sets/${applicationSetId}`);
  }


  withdrawApplication(programId, data) {
    return this.axios.post(`/programs/${programId}/users/withdraw`, data);
  }


  getOwnApplicationAnswers(params) {
    return this.axios.get(`/programs/${params.programId}/users/${params.userId}/applications/${params.formId}/answers`);
  }

  saveOwnApplicationAnswers(params, data) {
    return this.axios.post(`/programs/${params.programId}/users/${params.userId}/applications/${params.formId}/answers`, data);
  }

  importApplicationAnswers(programId, roleId, data) {
    return this.axios.post(`/programs/${programId}/applications/role/${roleId}/import`, data);
  }
  validateApplicationAnswers(programId, roleId, data) {
    return this.axios.post(`/programs/${programId}/applications/role/${roleId}/validate`, data);
  }

  patchProgramApplicationSet(programId, appSetId, data) {
    return this.axios.patch(`/programs/${programId}/application-sets/${appSetId}`, data);
  }
  
}

const applicationService = new ApplicationService(client);
export default applicationService;
