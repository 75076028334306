import client from './http';

class MatchesService {

  constructor(axios) {
    this.axios = axios;
  }
  getListLockedMatches(programId, search) {
    return this.axios.get(`/programs/${programId}/matches/locked`, {params: {search}});
  }
    
  getListPublishedCancelledCompletedMatches(programId, search) {
    return this.axios.get(`/programs/${programId}/matches`,  {params: {search}});
  }

  getMatch(programId, matchId) {
    return this.axios.get(`/programs/${programId}/matches/${matchId}`);
  }
  patchUpdateMatch(programId, matchId, data) {
    return this.axios.patch(`/programs/${programId}/matches/${matchId}`, data);
  }
  getMatchPools(programId, search) {
    return this.axios.get(`/programs/${programId}/pools`, {params: {search}});
  }
  listPoolUserCandidates(programId, search) {
    return this.axios.get(`/programs/${programId}/pools/users/candidates`, {params: {search}});
  }
  listPoolGroupCandidates(programId, search) {
    return this.axios.get(`/programs/${programId}/pools/groups/candidates`, {params: {search}});
  }
  listPoolUserPoolIdCandidates(programId, poolId, search) {
    return this.axios.get(`/programs/${programId}/pools/${poolId}/users/candidates`, {params: {search}});
  }
  listPoolGroupPoolIdCandidates(programId, poolId, search) {
    return this.axios.get(`/programs/${programId}/pools/${poolId}/groups/candidates`, {params: {search}});
  }
  createPool(programId, pool){
    return this.axios.post(`/programs/${programId}/pools`, pool);
  }
  deletePool(programId, poolId) {
    return this.axios.delete(`/programs/${programId}/pools/${poolId}`);
  }
  getMatchPool(programId, poolId, search) {
    return this.axios.get(`/programs/${programId}/pools/${poolId}`, {params: {search}});
  }
  patchMatchPool(programId, poolId, data) {
    return this.axios.patch(`/programs/${programId}/pools/${poolId}`, data);
  }
  getPoolMatches(programId, poolId, search) {
    return this.axios.get(`/programs/${programId}/pools/${poolId}/matches`,  {params: {search}});
  }
  listMatchCandidatesForUser(programId, userId, role, search) {
    return this.axios.get(`/programs/${programId}/users/${userId}/matches/candidates/${role}`,  {params: {search}});
  }
  listPoolMatchCandidatesForUser(programId, poolId, userId, role, search) {
    return this.axios.get(`/programs/${programId}/pools/${poolId}/users/${userId}/matches/candidates/${role}`,  {params: {search}});
  }
  postCreateMatch(programId, data) {
    return this.axios.post(`/programs/${programId}/matches`, data);
  }
  listUserMatches(programId, userId, search) {
    return this.axios.get(`/programs/${programId}/users/${userId}/matches`,  {params: {search}});
  }
  deletePoolUser(programId, poolId, userId, roleId) {
    return this.axios.delete(`/programs/${programId}/pools/${poolId}/users/${userId}/role/${roleId}`);
  }
  patchUpdateMatches(programId, data) {
    return this.axios.patch(`/programs/${programId}/matches`, data);
  }
  // eslint-disable-next-line camelcase
  getCompareUsers(programId, users) {
    return this.axios.get(`/programs/${programId}/matches/compare`,  {params: { match_users:JSON.stringify(users) }});
  }

  // match notes
  createMatchNote(programId, matchId, data) {
    return this.axios.post(`/programs/${programId}/matches/${matchId}/notes`, data);
  }
  listMatchNotes(programId, matchId, search) {
    return this.axios.get(`/programs/${programId}/matches/${matchId}/notes`, {params: {search}});
  }
  readMatchNote(programId, matchId, noteId) {
    return this.axios.get(`/programs/${programId}/matches/${matchId}/notes/${noteId}`);
  }
  updateMatchNote(programId, matchId, noteId, data) {
    return this.axios.patch(`/programs/${programId}/matches/${matchId}/notes/${noteId}`, data);
  }
  deleteMatchNote(programId, matchId, noteId) {
    return this.axios.delete(`/programs/${programId}/matches/${matchId}/notes/${noteId}`);
  }

  // Create zoom room
  createZoomMeetingRoom(userId) {
    return this.axios.post(`/users/${userId}/create-zoom-meeting`);
  }

  getMatchPreferences(programId, userId, search) {
    return this.axios.get(`/programs/${programId}/user/${userId}/match-preferences`, {params: {search}});
  }

  notifyMentor(programId, data) {
    return this.axios.post(`/programs/${programId}/notify-mentor`, data);
  }
}

const matchesService = new MatchesService(client);
export default matchesService;

